import ng from 'angular';
import { AcpMobileAppUpgradeDialog } from 'components/mobile-app-upgrade-dialog';
import { AcpPlaidPlugin } from 'components/plaid-domain';
import { AcpDeviceService } from 'core';
import { AcpConnectedBanksService } from './acp-connected-banks-service';
import acpConnectedBanksAddMoneyLandingDialogTemplate from './templates/acp-connected-banks-add-money-landing-dialog.html';
import acpConnectedBanksDeleteDialogTemplate from './templates/acp-connected-banks-delete-dialog.html';
import acpConnectedBanksListTemplate from './templates/acp-connected-banks-list.html';
import { ConnectedBank } from './types';

export class AcpConnectedBanksListComponentCtrl
  implements nsUtils.NsComponentController {
  isOACBankLinkRerouteEnabled = false;
  loading = true;
  canLinkMoreBanks: boolean;
  dailyLinkLimitReached: boolean;
  totalLinkLimitReached: boolean;
  connectedBanks: ConnectedBank[];
  selectedBank: ConnectedBank;
  isSubmitting: boolean;
  isCordova: boolean = this.acpDeviceService.isCordova();

  constructor(
    nsComponentDecorator,
    private $state,
    private acpConnectedBanksService: AcpConnectedBanksService,
    private $mdDialog: ng.material.IDialogService,
    private acpDeviceService: AcpDeviceService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpPlaidPlugin: AcpPlaidPlugin,
    private acpMobileAppUpgradeDialog: AcpMobileAppUpgradeDialog
  ) {
    'ngInject';

    nsComponentDecorator(this, this);
    this.fetchPermissions();
  }

  private async fetchPermissions(): Promise<void> {
    const permissionsResult: {
      [permissionId: string]: boolean;
    } = await this.nsPermissions.requestPermissions([
      'isOACBankLinkRerouteEnabled'
    ]);

    this.isOACBankLinkRerouteEnabled =
      permissionsResult.isOACBankLinkRerouteEnabled;
  }
  // This is needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
  async $onInit(): Promise<void> {
    if (await this.acpMobileAppUpgradeDialog.isUpgradeRequired()) {
      this.acpMobileAppUpgradeDialog.show();
      return;
    }

    try {
      if (!this.acpPlaidPlugin.isOAuthUrl()) {
        await this.checkIfCanLink();
        this.connectedBanks = await this.acpConnectedBanksService.getConnectedBanks();
      }
    } catch (ex) {
      this.$state.go('connected-banks.error');
    }

    this.$onValue(
      this.acpPlaidPlugin.plaidLoading,
      (loading) => (this.loading = loading)
    );

    if (!this.acpPlaidPlugin.isMobilePlaidEligible()) {
      await this.acpPlaidPlugin.initPlaid({
        onSuccess: (publicKey: string, metadata: any) => {
          this.acpPlaidPlugin.setPlaidLoading(true);
          this.handlePlaidLink(publicKey, metadata).then(() => {
            this.postAuthNavs('connected-banks');
          });
        },
        onExit: () => {
          this.postAuthNavs('connected-banks');
        }
      });
      this.acpPlaidPlugin.setPlaidLoading(false);
    } else {
      this.acpPlaidPlugin.setPlaidLoading(false);
    }
  }

  postAuthNavs = (lastpage: string) =>
    this.acpPlaidPlugin.pushHistoryStates([
      'dashboard',
      'your-account',
      lastpage
    ]);

  openPlaid(): void {
    if (this.acpPlaidPlugin.isMobilePlaidEligible()) {
      this.acpPlaidPlugin.openPlaidMobile({
        onSuccess: (publicKey: string, metadata: any) => {
          this.acpPlaidPlugin.setPlaidLoading(true);
          this.handlePlaidLink(publicKey, metadata);
        }
      });
    } else {
      this.acpPlaidPlugin.openPlaid();
    }
  }

  openDeleteDialog(index): void {
    this.selectedBank = this.connectedBanks[index];
    const vm = this;
    const controllerDeletePopUp = /*@ngInject*/ function ($scope) {
      $scope.vm = vm;
    };
    this.$mdDialog.show({
      template: acpConnectedBanksDeleteDialogTemplate,
      controller: controllerDeletePopUp
    });
  }

  openAddMoneyLandingDialog(): void {
    const vm = this;
    const controllerAddMoneyPopUp = /*@ngInject*/ function ($scope) {
      $scope.vm = vm;
    };
    this.$mdDialog.show({
      template: acpConnectedBanksAddMoneyLandingDialogTemplate,
      controller: controllerAddMoneyPopUp
    });
  }

  async deleteCard(): Promise<void> {
    this.isSubmitting = true;

    try {
      await this.acpConnectedBanksService.deleteCard({
        id: this.selectedBank.id
      });

      await this.checkIfCanLink();
      this.connectedBanks = await this.acpConnectedBanksService.getConnectedBanks();
    } catch (ex) {
      this.$state.go('connected-banks.error');
    } finally {
      this.isSubmitting = false;
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.$mdDialog.hide();
  }

  async handlePlaidLink(publicKey: string, metadata: any): Promise<void> {
    this.acpPlaidPlugin.setPlaidLoading(true);
    const { accounts, institution, link_session_id } = metadata;

    if (!accounts || !accounts.length) {
      // Plaid link failed - no accounts returned
      this.$state.go('connected-banks.error');
    }

    try {
      await this.acpConnectedBanksService.linkBankAccount({
        public_token: publicKey,
        accounts,
        institution,
        link_session_id,
        purpose: 'ach-transfers'
      });
    } catch (error) {
      this.acpPlaidPlugin.setPlaidLoading(false);
      this.postAuthNavs('connected-banks.link-error');
      this.$state.go('connected-banks.link-error');
      throw error;
    }

    this.connectedBanks = await this.acpConnectedBanksService.getConnectedBanks();
    await this.checkIfCanLink();

    this.acpPlaidPlugin.setPlaidLoading(false);
  }

  async checkIfCanLink() {
    const canLinkResponse = await this.acpConnectedBanksService.getCanLink();
    this.dailyLinkLimitReached = false;
    this.totalLinkLimitReached = false;

    if (!canLinkResponse.can_link && canLinkResponse.reason) {
      switch (canLinkResponse.reason) {
        case 'successful_24_link_limit':
          this.dailyLinkLimitReached = true;
          break;
        case 'max_accounts_link_limit':
          this.totalLinkLimitReached = true;
          break;
        default:
          throw new Error(`Unexpected link reason ${canLinkResponse.reason}`);
      }
    }
  }

  $onDestroy(): void {
    this.acpPlaidPlugin.destroyPlaid();
  }
}

export const acpConnectedBanksListComponent: ng.IComponentOptions = {
  controller: AcpConnectedBanksListComponentCtrl,
  controllerAs: 'vm',
  template: acpConnectedBanksListTemplate
};

export default acpConnectedBanksListComponent;
