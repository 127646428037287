import acpConnectedBanksLinkErrorTemplate from './templates/acp-connected-banks-link-error.html';

export class AcpConnectedBanksLinkErrorComponentCtrl {}

export const acpConnectedBanksLinkErrorComponent: ng.IComponentOptions = {
  controller: AcpConnectedBanksLinkErrorComponentCtrl,
  controllerAs: 'vm',
  template: acpConnectedBanksLinkErrorTemplate
};

export default acpConnectedBanksLinkErrorComponent;
