import acpConnectedBanksErrorTemplate from './templates/acp-connected-banks-error.html';

export class AcpConnectedBanksErrorComponentCtrl {}

export const acpConnectedBanksErrorComponent: ng.IComponentOptions = {
  controller: AcpConnectedBanksErrorComponentCtrl,
  controllerAs: 'vm',
  template: acpConnectedBanksErrorTemplate
};

export default acpConnectedBanksErrorComponent;
