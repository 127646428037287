import {
  CanLinkResponse,
  ConnectedBank,
  DeleteBankRequest,
  DeleteBankResponse,
  LinkBankAccountRequest,
  LinkBankAccountResponse,
  UpdateReauthRequest
} from './types';

export class AcpConnectedBanksService {
  updateReauth = this.webapiResource<UpdateReauthRequest, void>({
    method: 'PUT',
    path: '/v2/ach_transfer/:id/reauth_completed',
    format: 'json'
  });

  linkBankAccount = this.webapiResource<
    LinkBankAccountRequest,
    LinkBankAccountResponse
  >({
    method: 'POST',
    path: '/v2/ach_transfer/link',
    format: 'json'
  });

  getCanLink = this.webapiResource<void, CanLinkResponse>({
    method: 'GET',
    path: '/v2/ach_transfer/linked_bank_accounts/can_link',
    format: 'json'
  });

  getConnectedBanks = this.webapiResource<void, ConnectedBank[]>({
    method: 'GET',
    path: '/v2/ach_transfer/linked_bank_accounts',
    transformData: (accounts) => {
      return Array.isArray(accounts.accounts)
        ? accounts.accounts
        : [accounts.accounts];
    }
  });

  deleteCard = this.webapiResource<DeleteBankRequest, DeleteBankResponse>({
    method: 'DELETE',
    path: '/v2/ach_transfer/link/:id',
    format: 'json'
  });

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
